import React from 'react'
import Marquee from "react-fast-marquee";
import Brand1 from "../assets/Clint-logo/Barand-2.jpg";
import Brand2 from "../assets/Clint-logo/Barand-1.jpg";
import Brand3 from "../assets/Clint-logo/Barand-3.jpg";
import Brand4 from "../assets/Clint-logo/Barand-4.jpg";
import Brand5 from "../assets/Clint-logo/Barand-5.jpg";
import Brand6 from "../assets/Clint-logo/Barand-6.jpg";
import Brand7 from "../assets/Clint-logo/Barand-7.jpg";
import Brand8 from "../assets/Clint-logo/Barand-8.jpg";
import Brand9 from "../assets/Clint-logo/Barand-9.jpg";
import Brand10 from "../assets/Clint-logo/Barand-10.jpg";
import Brand11 from "../assets/Clint-logo/Barand-11.jpg";
import Brand12 from "../assets/Clint-logo/Barand-12.jpg";
import Brand13 from "../assets/Clint-logo/Barand-13.jpg";
import Brand14 from "../assets/Clint-logo/Barand-14.jpg";
import Brand15 from "../assets/Clint-logo/Barand-15.jpg";
import Brand16 from "../assets/Clint-logo/Barand-16.jpg";
import Brand17 from "../assets/Clint-logo/Barand-17.jpg";
import Brand18 from "../assets/Clint-logo/Barand-18.jpg";
import Brand19 from "../assets/Clint-logo/Barand-19.jpg";
import Brand20 from "../assets/Clint-logo/Barand-20.jpg";
import Brand21 from "../assets/Clint-logo/Barand-21.jpg";
import Brand22 from "../assets/Clint-logo/Barand-22.jpg";
import Brand23 from "../assets/Clint-logo/Barand-23.jpg";
const Scrolll = () => {
  return (
<main className="relative flex flex-col justify-center  overflow-hidden">
<div className="relative border-t border-gray-200 bg-gray-50">
  <div
    className="absolute inset-0 h-36 opacity-90 lg:h-48"
    style={{
      backgroundImage:
        'url("data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100"%3E%3Cg fill-rule="evenodd"%3E%3Cg fill="%23e0e7ff" fill-opacity="1"%3E%3Cpath opacity=".5" d="M96 95h4v1h-4v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9zm-1 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9z"/%3E%3Cpath d="M6 5V0H5v5H0v1h5v94h1V6h94V5H6z"/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")'
    }}
  ></div>
  <div className="relative mx-auto max-w-7xl px-6 pt-16 pb-12 sm:px-12 lg:pt-24">
    <header className="mx-auto max-w-2xl text-center">
      <h1 className="text-3xl font-extrabold tracking-tight text-main sm:text-4xl">
      Our Brands
      </h1>
      <p className="mt-2 text-sm font-semibold text-gray-400">
      We ship this brand product
      </p>
    </header>
  </div>
  <div className="w-full max-w-5xl  mx-auto px-4 md:px-6 py-10">
    <div className="text-center">

      <div
       
        className="w-full inline-flex flex-nowrap overflow-hidden [mask-image:_linear-gradient(to_right,transparent_0,_black_128px,_black_calc(100%-128px),transparent_100%)]"
      >
        <Marquee speed={100} delay={0}
        autoFill pauseOnClick direction="left"
      
        pauseOnHover
         
          className="flex items-center justify-center list-none md:justify-start [&_li]:mx-8 [&_img]:max-w-none animate-infinite-scroll"
        >
          
          <li>
          <img src={Brand1} className=' h-12' alt="Spark" />
         </li>
         <li>
          <img src={Brand1} className=' h-12' alt="Spark" />
         </li>
         <li>
          <img src={Brand2} className=' h-12' alt="Spark" />
         </li>
         <li>
          <img src={Brand3} className=' h-12' alt="Spark" />
         </li>
         <li>
          <img src={Brand4} className='h-12 ' alt="Spark" />
         </li>
         <li>
          <img src={Brand5} className=' h-12' alt="Spark" />
         </li>
         <li>
          <img src={Brand6} className=' h-12' alt="Spark" />
         </li>
         <li>
          <img src={Brand7} className='h-12' alt="Spark" />
         </li>
         <li>
          <img src={Brand7} className='h-12' alt="Spark" />
         </li>
         <li>
          <img src={Brand8} className=' h-12' alt="Spark" />
         </li>
         <li>
          <img src={Brand9} className=' h-12' alt="Spark" />
         </li>
         <li>
          <img src={Brand10} className=' h-12' alt="Spark" />
         </li>
         <li>
          <img src={Brand11} className=' h-12' alt="Spark" />
         </li>
         <li>
          <img src={Brand12} className=' h-12' alt="Spark" />
         </li>
         <li>
          <img src={Brand13} className=' h-12' alt="Spark" />
         </li>


         
          
         
         
         
        </Marquee>
        
      </div>

      <div
       
       className="w-full inline-flex flex-nowrap overflow-hidden [mask-image:_linear-gradient(to_right,transparent_0,_black_128px,_black_calc(100%-128px),transparent_100%)]"
     >
       <Marquee speed={100} delay={0}
       autoFill pauseOnClick direction="right"
     
       pauseOnHover
        
         className="flex items-center justify-center list-none md:justify-start [&_li]:mx-8 [&_img]:max-w-none animate-infinite-scroll"
       >
         
         <li>
          <img src={Brand13} className=' h-12' alt="Spark" />
         </li>
         <li>
          <img src={Brand14} className=' h-12' alt="Spark" />
         </li>
         <li>
          <img src={Brand15} className=' h-12' alt="Spark" />
         </li>
         <li>
          <img src={Brand16} className=' h-12' alt="Spark" />
         </li>
        
         <li>
          <img src={Brand1} className=' h-12' alt="Spark" />
         </li>
        
         <li>
          <img src={Brand17} className=' h-12' alt="Spark" />
         </li>
         <li>
          <img src={Brand18} className=' h-12' alt="Spark" />
         </li>
         <li>
          <img src={Brand19} className=' h-12' alt="Spark" />
         </li>
         <li>
          <img src={Brand20} className=' h-12' alt="Spark" />
         </li>
         <li>
          <img src={Brand21} className=' h-12' alt="Spark" />
         </li>

         <li>
          <img src={Brand22} className=' h-12' alt="Spark" />
         </li>
         <li>
          <img src={Brand23} className=' h-12' alt="Spark" />
         </li>
        
       </Marquee>
       
     </div>
      {/* End: Logo Carousel animation */}
    </div>
  </div>
</div>

 

</main>

  )
}

export default Scrolll
