import "./App.css";
import Routing from "./router/Routing";
import { FloatingWhatsApp } from "react-floating-whatsapp";
import Avater from '../src/assets/Chatbot.jpg'
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";

function App() {


  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  return (
    <>
       <FloatingWhatsApp
        phoneNumber=" +91 93154 63262"
        onNotification={1}
        accountName="Akash kumar "
        statusMessage="online"
        avatar={Avater}
        showAvailableOnDesktop={true}
        showAvailableOnMobile={true}
        notificationDelay ={60}
      />
      <Routing />
    </>
  );
}

export default App;
