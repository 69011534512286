import React, { useState } from 'react'
import { FaFacebookF, FaInstagram, FaLinkedinIn } from 'react-icons/fa';
import logo from '../assets/logo-png.png'
import { IoMdMenu } from "react-icons/io";
import { IoMdClose } from "react-icons/io";
import { Link, NavLink, Outlet } from "react-router-dom";
const Header = () => {
  const [isMenuOpen, setisMenuOpen] = useState(false);
  
  return (
 <header  className="bg-white border-gray-200 dark:bg-white fixed w-full top-0 left-0 shadow-xl z-10   min-h-[70px] ">
  <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
    <Link to="/" className='flex '>
      <img
        src={logo}
        alt="logo"
        className="w-10 "
      />
   
    </Link>
    
    <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
    

          <div className="hidden w-full md:block md:w-auto" id="navbar-default">
            <ul className="font-medium flex flex-col p-4  md:p-0 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 rtl:space-x-reverse md:mt-0 md:border-0 md:bg-white  ">
              <NavLink
               style={({ isActive }) => {
                return isActive ? { color: "#fea700" } : {};
              }}
                to="/"
                className="relative text-black  hover:text-main cursor-pointer transition-all ease-in-out before:transition-[width] before:ease-in-out before:duration-700 before:absolute before:bg-main before:origin-center before:h-[1px] before:w-0 hover:before:w-[50%] before:bottom-0 before:left-[50%] after:transition-[width] after:ease-in-out after:duration-700 after:absolute after:bg-main after:origin-center after:h-[1px] after:w-0 hover:after:w-[50%] after:bottom-0 after:right-[50%]"
              >
                Home
              </NavLink>
              <li>
                <NavLink
                 style={({ isActive }) => {
                  return isActive ? { color: "#fea700" } : {};
                }}
                  to="/products"
                  className="relative text-black isActive hover:text-main cursor-pointer transition-all ease-in-out before:transition-[width] before:ease-in-out before:duration-700 before:absolute before:bg-main before:origin-center before:h-[1px] before:w-0 hover:before:w-[50%] before:bottom-0 before:left-[50%] after:transition-[width] after:ease-in-out after:duration-700 after:absolute after:bg-main after:origin-center after:h-[1px] after:w-0 hover:after:w-[50%] after:bottom-0 after:right-[50%]"
                >
                Products
                </NavLink>
              </li>
              <li>
                <NavLink
                 style={({ isActive }) => {
                  return isActive ? { color: "#fea700" } : {};
                }}
                  to="/lincene"
                  className="relative text-black hover:text-main cursor-pointer transition-all ease-in-out before:transition-[width] before:ease-in-out before:duration-700 before:absolute before:bg-main before:origin-center before:h-[1px] before:w-0 hover:before:w-[50%] before:bottom-0 before:left-[50%] after:transition-[width] after:ease-in-out after:duration-700 after:absolute after:bg-main after:origin-center after:h-[1px] after:w-0 hover:after:w-[50%] after:bottom-0 after:right-[50%]"
                >
                 License
                </NavLink>
              </li>
              <li>
                <NavLink
                 style={({ isActive }) => {
                  return isActive ? { color: "#fea700" } : {};
                }}
                  to="/service"
                  className="relative text-black hover:text-main cursor-pointer transition-all ease-in-out before:transition-[width] before:ease-in-out before:duration-700 before:absolute before:bg-main before:origin-center before:h-[1px] before:w-0 hover:before:w-[50%] before:bottom-0 before:left-[50%] after:transition-[width] after:ease-in-out after:duration-700 after:absolute after:bg-main after:origin-center after:h-[1px] after:w-0 hover:after:w-[50%] after:bottom-0 after:right-[50%]"
                >
                  Service
                </NavLink>
              </li>
              <li>
                <NavLink
                 style={({ isActive }) => {
                  return isActive ? { color: "#fea700" } : {};
                }}
                  to="/contact"
                  className="relative text-black hover:text-main cursor-pointer transition-all ease-in-out before:transition-[width] before:ease-in-out before:duration-700 before:absolute before:bg-main before:origin-center before:h-[1px] before:w-0 hover:before:w-[50%] before:bottom-0 before:left-[50%] after:transition-[width] after:ease-in-out after:duration-700 after:absolute after:bg-main after:origin-center after:h-[1px] after:w-0 hover:after:w-[50%] after:bottom-0 after:right-[50%]"
                >
                 Contact
                </NavLink>
              </li>
            </ul>
          </div>
        </div>

    
    <div className="flex max-lg:ml-auto space-x-1">
      <button className="px-4 py-2 text-sm rounded-full font-bold  bg-main transition-all ease-in-out duration-300 hover:bg-transparent hover:text-[#007bff]">
        <Link  target="_blank" to="https://www.facebook.com/people/SafetyTech-H/61552983945991/" ><FaFacebookF size={20}  /></Link>
      
      </button>
      <button className="px-4 py-2 text-sm rounded-full font-bold bg-main  transition-all ease-in-out duration-300 hover:bg-transparent hover:text-[#007bff]">
      <Link  target="_blank" to="https://www.linkedin.com/in/tech-safety-4734162a8/" >  <FaLinkedinIn  size={20}  /></Link>
    
      </button>
      <button className="px-4 py-2 text-sm rounded-full font-bold  bg-main transition-all ease-in-out duration-300 hover:bg-transparent hover:text-[#007bff]">
      <Link  target="_blank" to="https://www.instagram.com/safetytechhh/" >   <FaInstagram  size={20} /></Link>
     
      </button>
      <button onClick={() => setisMenuOpen(!isMenuOpen)}
            data-collapse-toggle="navbar-default"
            type="button"
            className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
            aria-controls="navbar-default"
            aria-expanded="false"
          >
            <span className="sr-only">Open main menu</span>

            {isMenuOpen ? (
              <IoMdClose size={20} className="w-10 h-7  text-gray-500" />
            ) : (
              <IoMdMenu className="w-10 h-7 text-gray-500" />
            )}
          </button>

    </div>
  </div>
  {isMenuOpen && (
          <ul className="lg:flex gap-x-5 max-lg:space-y-3 max-lg:fixed max-lg:bg-white max-lg:w-1/2 max-lg:min-w-[300px] max-lg:top-0 max-lg:left-0 max-lg:p-6 max-lg:h-full max-lg:shadow-md max-lg:overflow-auto z-50">
          <li className="mb-6 hidden max-lg:block">
            <a href="#">
              <img
                src={logo}
                alt="logo"
                className="w-10"
              />
            </a>
          </li>
          <li className="max-lg:border-b border-gray-300 max-lg:py-3 px-3">
            <NavLink
             to="/"
             style={({ isActive }) => {
              return isActive ? { color: "#fea700" } : {};
            }}
              className="hover:text-main text-black block font-semibold text-[15px]"
            >
              Home
            </NavLink>
          </li>
          <li className="max-lg:border-b border-gray-300 max-lg:py-3 px-3">
            <NavLink
                style={({ isActive }) => {
                  return isActive ? { color: "#fea700" } : {};
                }}
              to="/products"
              className="hover:text-main text-black block font-semibold text-[15px]"
            >
             Products
            </NavLink>
          </li>
          <li className="max-lg:border-b border-gray-300 max-lg:py-3 px-3">
            <NavLink  
             style={({ isActive }) => {
              return isActive ? { color: "#fea700" } : {};
            }}
           to="/lincene"
              className="hover:text-main text-black block font-semibold text-[15px]"
            >
            License
            </NavLink>
          </li>
          <li className="max-lg:border-b border-gray-300 max-lg:py-3 px-3">
            <NavLink
                style={({ isActive }) => {
                  return isActive ? { color: "#fea700" } : {};
                }}
             to="/service"
              className="hover:text-main text-black block font-semibold text-[15px]"
            >
             Service
            </NavLink>
          </li>
         
          <li className="max-lg:border-b border-gray-300 max-lg:py-3 px-3">
            <NavLink
               style={({ isActive }) => {
                return isActive ? { color: "#fea700" } : {};
              }}
                to="/contact"
              className="hover:text-main text-black block font-semibold text-[15px]"
            >
              Contact
            </NavLink>
          </li>
        </ul>
        )}

        
</header>

  )
}

export default Header
