import React from 'react'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Data from '../Data/Sdata'
import { IoIosCall } from 'react-icons/io';
import { FaWhatsapp } from 'react-icons/fa';
const Carouselproduct = () => {
    const responsive = {
        desktop: {
          breakpoint: { max: 4000, min: 1024 },
          items: 3,
          slidesToSlide: 2 // optional, default to 1.
        },
      
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 2,
          slidesToSlide: 2 // optional, default to 1.
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items:1,
          slidesToSlide: 1 // optional, default to 1.
        }
      };
  return (
    <div className=''>
  
    <div className="relative bg-black  pt-16 pb-20 lg:px-8 lg:pt-24 lg:pb-28">
 
  <div className="relative mx-auto max-w-7xl ">
  <h1 className='text-main text-2xl mb-10 '>OUR PRODUCTS</h1>
    <div >
    <Carousel className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 mobile-desktop'
    responsive={responsive}
    autoPlay={true}
    swipeable={true}
    draggable={true}

    infinite={true}
    partialVisible={false}
    dotListClass="custom-dot-list-style"
   
  >
  

{
  Data.map((i)=>(
<div className="bg-white rounded overflow-hidden shadow-md cursor-pointer hover:scale-[1.02] transition-all max-w-96  ">
      <div className="w-full aspect-w-16 aspect-h-8 lg:h-80 ">
        <img
          src={i.imgsrc}
          alt="Product 1"
          className="h-[300px] w-full object-cover object-top "
        />
      </div>
      <div className="p-4  ">
        <h3 className="  text-gray-800 ">
          {i.heading}
        </h3>
        <div className="mt-4 flex items-center flex-wrap gap-2">
          {/* <h4 className="text-lg font-bold text-gray-800"><FaWhatsapp /></h4> */}
          <div className="bg-gray-100 w-10 h-10 flex items-center justify-center rounded-full cursor-pointer ml-auto">
          <IoIosCall size={20} />
          </div>
        </div>
      </div>
    </div>
  ))
}



    
    
  </Carousel>
     
    </div>
  </div>
</div>

 
    </div>
 

  
  )
}

export default Carouselproduct
