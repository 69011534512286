import React from "react";
import Home from "../Pages/Home";
import { Routes, Route } from "react-router-dom";
import Product from "../Pages/ProductComponets/Product";
import Service from "../Pages/Service";
import License from "../Pages/License";
import { Contact } from "../Pages/Contact";
import Header from "../Componets/Header";
import Footer from "../Componets/Footer";

const Routing = () => {
  return (
<>

<Header/>
<Routes>
      <Route   path="/" element={<Home/>} />
        <Route  path="/products" element={<Product/>} />
        <Route  path="/service" element={<Service />} />
        <Route  path="/lincene" element={<License />} />
        <Route  path="/contact" element={<Contact />} />
     
 </Routes>
 
  <Footer/>
</>
  );
};

export default Routing;
