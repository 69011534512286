
import image0 from "../ProductComponets/Assets/Product-1.jpg";
import image1 from "../ProductComponets/Assets/Product-2.jpg";
import image2 from "../ProductComponets/Assets/Product-3.jpg";
import image3 from "../ProductComponets/Assets/Product-4.jpg";
import image4 from "../ProductComponets/Assets/Product-5.jpg";
import image5 from "../ProductComponets/Assets/Fire Sprinkler/Automatic Sidewall Fire Sprinkler - VariEx® - (Pack of 5).jpg";
import image6 from "../ProductComponets/Assets/Fire Sprinkler/Automatic Upright Fire Sprinkler - VariEx® - (Pack of 5).jpg";
import image7 from "../ProductComponets/Assets/Fire Sprinkler/Fire Sprinkler Pendent - VariEx® - (Pack of 10).jpg";
import image8 from "../ProductComponets/Assets/Fire Sprinkler/Fire Sprinkler Sidewall - VariEX® - (Pack of 10).jpg";
import image9 from "../ProductComponets/Assets/Fire Sprinkler/Fire Sprinkler Upright - VariEx® - (Pack of 10).jpg";
import image10 from "../ProductComponets/Assets/Safety Signages/Fire Extinguishers12x4-600x600.jpeg";
import image11 from "../ProductComponets/Assets/Safety Signages/Fire Extinguisher-600x600.png";
import image12 from "../ProductComponets/Assets/Safety Signages/Fire Extinguishers12x4-600x600.jpeg";
import image13 from "../ProductComponets/Assets/Safety Signages/Left Exit-600x600.jpeg";
import image14 from "../ProductComponets/Assets/Safety Signages/Right Exit-600x600.jpeg";




const Data = [
  // Automotive Retouching
  {
    id: 1,
    category: "Fire Extinguishers",
    peoductAbout: "Car Fire Extinguisher Stop Fire 500 ml",
    Imghero: image0,
  }, 
  {
    id: 2,
    category: "Fire Extinguishers",
    peoductAbout: "ABC Powder Type Fire Extinguisher - 6Kg",
    Imghero: image1,
  },
  {
    id: 3,
    category: "Fire Extinguishers",
    peoductAbout: "ABC Powder Type Fire Extinguisher - 4Kg",
    Imghero: image2,
  },
  {
    id: 4,
    category: "Fire Extinguishers",
    peoductAbout: "ABC Powder Type Fire Extinguisher - 2Kg",
    Imghero: image3,
  },
  {
    id: 5,
    category: "Fire Extinguishers",
    peoductAbout: "CO₂ Fire Extinguisher - 2Kg",
    Imghero: image4,
  },
  {
    id: 5,
    category: "Fire Sprinkler",
    peoductAbout: "Automatic Sidewall Fire Sprinkler - VariEx® - (Pack of 5)",
    Imghero: image5,
  },
  {
    id: 6,
    category: "Fire Sprinkler",
    peoductAbout: "Automatic Upright Fire Sprinkler - VariEx® - (Pack of 5)",
    Imghero: image6,
  },
  {
    id: 7,
    category: "Fire Sprinkler",
    peoductAbout: "Fire Sprinkler Pendent - VariEx® - (Pack of 10).jpg",
    Imghero: image7,
  },
  {
    id: 8,
    category: "Fire Sprinkler",
    peoductAbout: "Fire Sprinkler Sidewall - VariEX® - (Pack of 10).jpg",
    Imghero: image8,
  },
  {
    id: 9,
    category: "Fire Sprinkler",
    peoductAbout: "Fire Sprinkler Upright - VariEx® - (Pack of 10)",
    Imghero: image9,
  },
 
  {
    id: 10,
    category: "Safety Signages",
    peoductAbout: "Photo-Luminescent (Glow in Dark) Fire Extinguisher Signage Board (12x4 Inches)",
    Imghero: image10,
  },
  {
    id: 11,
    category: "Safety Signages",
    peoductAbout: "Photo-Luminescent (Glow in Dark) Fire Exit Signage Board (12x4 Inches)",
    Imghero: image11,
  },
  {
    id: 12,
    category: "Safety Signages",
    peoductAbout: "Photo-Luminescent (Glow in Dark) Fire Exit Signage Board - Left Arrow(12x4 Inches)",
    Imghero: image12,
  },
  {
    id: 13,
    category: "Safety Signages",
    peoductAbout: "Photo-Luminescent (Glow in Dark) Fire Exit Signage Board - Left Arrow(12x4 Inches)",
    Imghero: image13,
  },
  {
    id: 14,
    category: "Safety Signages",
    peoductAbout: "Photo-Luminescent (Glow in Dark) Fire Extinguisher Signage Board (12x4 Inches)",
    Imghero: image14,
  },
 
];
export default Data;
