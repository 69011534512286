import React from 'react'
import img1 from '../assets/H-1.jpg'
import img2 from '../assets/H-2.jpg'
import img3 from '../assets/H-3.jpg'
import img4 from '../assets/p-3.jpeg'
const ProductHome = () => {
  return (
  
 <div className=" mx-auto p-5 sm:p-10 md:p-16 bg-black w-full h-auto">
   {/* <h1 className='text-main text-3xl mb-2'>Our Product</h1> */}
  <div className="grid grid-cols-1 md:grid-cols-4 sm:grid-cols-2 gap-5">
   
  <div className=" rounded-md overflow-hidden cursor-pointer">
        <div className="w-full overflow-hidden">
          <img
           src={img4}
            alt="Product 1"
            className="h-full w-full object-cover object-top hover:scale-110 transition-all"
          />
        </div>
        <div className="p-6">
          <div className="mb-6 flex items-center justify-center flex-wrap gap-4">
            <h3 className="text-lg font-bold text-main">Analogue Isolator Base
            – B524IEFT-1</h3>
            <p className="text-lg text-white">The System Sensor B524IEFT-1 is a specialist base in addition to the traditional low profile or deep bases, designed for use with all System Sensor 200 analogue addressable detectors.</p>
          </div>
         
        </div>
      </div>
      <div className=" rounded-md overflow-hidden cursor-pointer">
        <div className="w-full overflow-hidden">
          <img
            src={img1}
            alt="Product 1"
            className="h-full w-full object-cover object-top hover:scale-110 transition-all"
          />
        </div>
        <div className="p-6">
          <div className="mb-6 flex items-center justify-center flex-wrap gap-4">
            <h3 className="text-lg font-bold text-main">NFS2-640 Honeywell Notifier Fire Control Panel</h3>
            <p className=" text-white">The NFS2-640 intelligent fire alarm control panel is p art of NOTIFIER's UL listed ONYX Series. It's designed specif ically for mid-size applications, and easily expanded vi a NOTIFIER's intelligent fire alarm network.</p>
          </div>
         
        </div>
      </div>
      <div className=" rounded-md overflow-hidden cursor-pointer">
        <div className="w-full overflow-hidden">
          <img
               src={img2}
            alt="Product 1"
            className="h-full w-full object-cover object-top hover:scale-110 transition-all"
          />
        </div>
        <div className="p-6">
          <div className="mb-6 flex items-center justify-center flex-wrap gap-4">
            <h3 className="text-lg font-bold text-main">Detect Fire ABC Fire Extinguisher</h3>
            <p className=" text-white">6kg powder portable fire extinguisher (stored pressure/gas cartridge type)</p>
          </div>
         
        </div>
      </div>
      <div className=" rounded-md overflow-hidden cursor-pointer">
        <div className="w-full overflow-hidden">
          <img
              src={img3}
            alt="Product 1"
            className="h-full w-full object-cover object-top hover:scale-110 transition-all"
          />
        </div>
        <div className="p-6">
          <div className="mb-6 flex items-center justify-center flex-wrap gap-4">
            <h3 className="text-lg font-bold text-main">Autocall Fire Alarm Panels</h3>
            <p className="text-lg text-white">Heavy Fire Protection for Light Commercial Building</p>
          </div>
         
        </div>
      </div>
  </div>
</div>

  
  )
}

export default ProductHome
