import { FaFacebookF, FaLinkedin, FaInstagram } from "react-icons/fa";
import { Link } from "react-router-dom";
import logo from "../assets/logo-png.png";

const Footer = () => {
  return (
    <>
      <>
      
        <footer className="bg-[#2d2e33]" aria-labelledby="footer-heading">
          <h2 id="footer-heading" className="sr-only">
            Footer
          </h2>
          <div className="mx-auto max-w-7xl px-6 pb-8 pt-16 sm:pt-24 lg:px-8 lg:pt-32">
            <div className="xl:grid xl:grid-cols-3 xl:gap-8">
              <div className="space-y-8">
                <img className="h-20" src={logo} alt="Company name" />
                {/* <p className="text-sm leading-6 text-gray-600">
            Making football look better to the world and a more respecful game.
          </p> */}
                <div className="flex space-x-6">
                  <a href="#" className="text-gray-400 hover:text-gray-500">
                    <span className="sr-only">Facebook</span>
                    <FaFacebookF />
                  </a>
                  <a href="#" className="text-yellow-500 hover:text-gray-500">
                    <span className="sr-only">the team is on fire</span>
                    <FaLinkedin />
                  </a>
                  <a
                    href="#"
                    className="text-gray-400 hover:text-blue-4      00"
                  >
                    <span className="sr-only">X</span>
                    <FaInstagram />
                  </a>
                </div>
              </div>
              <div className="mt-16 grid grid-cols-2 gap-8 xl:col-span-2 xl:mt-0">
                <div className="md:grid md:grid-cols-2 md:gap-8">
                  <div>
                    <h3 className="text-base font-semibold leading-6 text-main">
                      Home
                    </h3>
                    <ul role="list" className="mt-6 space-y-4">
                      <li>
                        <Link
                          to ="/"
                          className="text-sm leading-6 text-white relative hover:text-main cursor-pointer transition-all ease-in-out before:transition-[width] before:ease-in-out before:duration-700 before:absolute before:bg-main before:origin-center before:h-[1px] before:w-0 hover:before:w-[50%] before:bottom-0 before:left-[50%] after:transition-[width] after:ease-in-out after:duration-700 after:absolute after:bg-main after:origin-center after:h-[1px] after:w-0 hover:after:w-[50%] after:bottom-0 after:right-[50%]"
                        >
                          Home
                        </Link>
                      </li>
                      <li>
                        <Link
                         to="/products"
                          className="text-sm leading-6 text-white relative hover:text-main cursor-pointer transition-all ease-in-out before:transition-[width] before:ease-in-out before:duration-700 before:absolute before:bg-main before:origin-center before:h-[1px] before:w-0 hover:before:w-[50%] before:bottom-0 before:left-[50%] after:transition-[width] after:ease-in-out after:duration-700 after:absolute after:bg-main after:origin-center after:h-[1px] after:w-0 hover:after:w-[50%] after:bottom-0 after:right-[50%]"
                        >
                          Products
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/lincene"
                          className="text-sm leading-6 text-white relative hover:text-main cursor-pointer transition-all ease-in-out before:transition-[width] before:ease-in-out before:duration-700 before:absolute before:bg-main before:origin-center before:h-[1px] before:w-0 hover:before:w-[50%] before:bottom-0 before:left-[50%] after:transition-[width] after:ease-in-out after:duration-700 after:absolute after:bg-main after:origin-center after:h-[1px] after:w-0 hover:after:w-[50%] after:bottom-0 after:right-[50%]"
                        >
                          License
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/service"
                          className="text-sm leading-6 text-white relative hover:text-main cursor-pointer transition-all ease-in-out before:transition-[width] before:ease-in-out before:duration-700 before:absolute before:bg-main before:origin-center before:h-[1px] before:w-0 hover:before:w-[50%] before:bottom-0 before:left-[50%] after:transition-[width] after:ease-in-out after:duration-700 after:absolute after:bg-main after:origin-center after:h-[1px] after:w-0 hover:after:w-[50%] after:bottom-0 after:right-[50%]"
                        >
                          Service
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/contact"
                          className="text-sm leading-6 text-white relative hover:text-main cursor-pointer transition-all ease-in-out before:transition-[width] before:ease-in-out before:duration-700 before:absolute before:bg-main before:origin-center before:h-[1px] before:w-0 hover:before:w-[50%] before:bottom-0 before:left-[50%] after:transition-[width] after:ease-in-out after:duration-700 after:absolute after:bg-main after:origin-center after:h-[1px] after:w-0 hover:after:w-[50%] after:bottom-0 after:right-[50%]"
                        >
                          Contact
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="mt-10 md:mt-0">
                    <h3 className="text-base font-semibold leading-6 text-main">
                      Products
                    </h3>
                    <ul role="list" className="mt-6 space-y-4">
                      <li>
                        <a href="#" className="text-sm leading-6 text-white ">
                          Fire alarm system
                        </a>
                      </li>
                      <li>
                        <a href="#" className="text-sm leading-6 text-white">
                          Emergency system
                        </a>
                      </li>
                      <li>
                        <a href="#" className="text-sm leading-6 text-white">
                          Fire fighting system
                        </a>
                      </li>
                      <li>
                        <a href="#" className="text-sm leading-6 text-white">
                          Fire suppression system
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="md:grid md:grid-cols-2 md:gap-8">
                  <div>
                    <h3 className="text-base font-semibold leading-6 text-main">
                      Head Office
                    </h3>
                    <ul role="list" className="mt-6 space-y-4">
                      <li>
                        <a href="#" className="text-sm leading-6 text-white ">
                          Krishna Vihar, M-20, Kuti Rd, Bhopura, Ghaziabad,
                          Uttar Pradesh 201005, INDIA
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="mt-10 md:mt-0">
                    <h3 className="text-base font-semibold leading-6 text-main">
                      Inquiries
                    </h3>
                    <ul role="list" className="mt-6 space-y-4">
                      <li>
                        <a
                          href="tel:+918826972993"
                          className="text-sm leading-6 text-white "
                        >
                          For any inquiries, questions or recommendations,
                          please call: +918826972993
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-16 border-t border-gray-900/10 pt-8 sm:mt-20 lg:mt-24">
              <p className="text-base leading-5 text-white">
                © Copyright 2024 Tech Safety Ltd. All Rights Reserved.
              </p>
            </div>
          </div>
        </footer>
      </>
    </>
  );
};

export default Footer;
