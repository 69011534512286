import slider1 from '../assets/Slider-1.jpg'
import slider2 from '../assets/Slider-2.jpg'
import slider3 from '../assets/Slider-3.jpg'
import slider4 from '../assets/Slider-4.jpg'
import slider5 from '../assets/Slider-5.jpg'
import slider6 from '../assets/Slider-6.jpg'
// Import Barnd Logo Img


export const BannerData = [
    {
        name: "Powerful Security Protection",
    desc: "We focus on long-term relationships with customers.",
    titleImage: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRyb8a5FsWlYvlblcjCbetvw-1yoI2jpElNJC0TFcvUIU7F-8-jOrL5sbcrsV63N5EMY20-gNZCEzPPeO4LzDsYZnrEuQrM2OPkX2wHLg",
    image:slider1,
    category: " Romance/Comedy",
    language: "Tamil",
    year: 2015,
    time: "3hr",
    video: "",
    rate: 5,
    rating: 1,
    },
  
  {
    name: "Powerful Security Protection",
desc: "We focus on long-term relationships with customers.",
titleImage: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRyb8a5FsWlYvlblcjCbetvw-1yoI2jpElNJC0TFcvUIU7F-8-jOrL5sbcrsV63N5EMY20-gNZCEzPPeO4LzDsYZnrEuQrM2OPkX2wHLg",
image:slider2,
category: " Romance/Comedy",
language: "Tamil",
year: 2015,
time: "3hr",
video: "",
rate: 5,
rating: 1,
},
{
  name: "Powerful Security Protection",
desc: "We focus on long-term relationships with customers.",
titleImage: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRyb8a5FsWlYvlblcjCbetvw-1yoI2jpElNJC0TFcvUIU7F-8-jOrL5sbcrsV63N5EMY20-gNZCEzPPeO4LzDsYZnrEuQrM2OPkX2wHLg",
image:slider3,
category: " Romance/Comedy",
language: "Tamil",
year: 2015,
time: "3hr",
video: "",
rate: 5,
rating: 1,
},
{
  name: "Powerful Security Protection",
desc: "We focus on long-term relationships with customers.",
titleImage: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRyb8a5FsWlYvlblcjCbetvw-1yoI2jpElNJC0TFcvUIU7F-8-jOrL5sbcrsV63N5EMY20-gNZCEzPPeO4LzDsYZnrEuQrM2OPkX2wHLg",
image:slider4,
category: " Romance/Comedy",
language: "Tamil",
year: 2015,
time: "3hr",
video: "",
rate: 5,
rating: 1,
},
{
  name: "Powerful Security Protection",
desc: "We focus on long-term relationships with customers.",
titleImage: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRyb8a5FsWlYvlblcjCbetvw-1yoI2jpElNJC0TFcvUIU7F-8-jOrL5sbcrsV63N5EMY20-gNZCEzPPeO4LzDsYZnrEuQrM2OPkX2wHLg",
image:slider5,
category: " Romance/Comedy",
language: "Tamil",
year: 2015,
time: "3hr",
video: "",
rate: 5,
rating: 1,
},
{
  name: "Powerful Security Protection",
desc: "We focus on long-term relationships with customers.",
titleImage: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRyb8a5FsWlYvlblcjCbetvw-1yoI2jpElNJC0TFcvUIU7F-8-jOrL5sbcrsV63N5EMY20-gNZCEzPPeO4LzDsYZnrEuQrM2OPkX2wHLg",
image:slider6,
category: " Romance/Comedy",
language: "Tamil",
year: 2015,
time: "3hr",
video: "",
rate: 5,
rating: 1,
},
]