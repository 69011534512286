import React from 'react'
import ProductHome from './ProductHome'
import Scrolll from './Scrolll'

const Service = () => {
  return (
    <div className='mt-20 py-12'>
        <ProductHome/>
      <Scrolll/>
 
    </div>
  )
}

export default Service
