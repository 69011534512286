import * as React from "react";
import { BannerData } from "../Data/BannerData";
import { Autoplay, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";
// Import Swiper styles
import { Link } from "react-router-dom";
import { GrContact } from "react-icons/gr";
import About from "./About";
import ProductHome from "./ProductHome";
import Scrolll from "./Scrolll";
import Customers from "./Customers";
import Carouselproduct from "./Carouselproduct";

import { Contact } from "./Contact";

const Home = () => {
  const [counterone, setcounterone] = React.useState(false);
  return (
    <>
      <div className="relative w-full ">
        <Swiper
          direction="vertical"
          spaceBetween={0}
          slidesPerView={1}
          loop={true}
          speed={1000}
          modules={[Navigation, Autoplay]}
          autoplay={{ delay: 1000, disableOnInteraction: false }}
          className="w-full xl:h-96 background-img bg-dry lg:h-80 h-60 mt-20"
        >
          {BannerData.slice(0, 6).map((movies, index) => (
            <SwiperSlide
              key={index}
              className="relative rounded overflow-hidden"
            >
              <img
                src={`${movies.image}`}
                alt={movies.name}
                className="w-full h-full object-cover"
              />
              <div className="absolute linear-bg xl:pl-52 sm:p1-32 pl-8 top-0 bottom-0 right-0 left-0 flex flex-col justify-center lg:gap-8 md:gap-5 gap-4">
              <div data-aos="fade-left" data-aos-anchor="#example-anchor"  data-aos-offset="500"data-aos-duration="500">
              <h1 className="xl:text-6xl truncate capitalize text-white  font-sans sm:text-2xl text-xl font-bold">
                  {movies.name}
                </h1>
              </div>
                
                <div className="flex gap-5 items-center text-dryGray">
                  <h1>{movies.desc}</h1>
                </div>
                <div className="flex gap-5 items-center ">
                  <Link
                    to="/products"
                    className="bg-main hover:bg-black transition text-white px-8 py-3 rounded font-medium sm:text-sm text-xs"
                  >
                    View Product
                  </Link>
                  <button className="bg-white hover:text-main transition text-white px-4 py-3 rounded font-medium sm:text-sm bg-opacity-30">
                    <Link to="/contact">
                    <GrContact size={20} /></Link>
                  
                  </button>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        <ScrollTrigger
          onEnter={() => setcounterone(true)}
          onExit={() => setcounterone(false)}
        >
          <div className="mx-auto max-w-8xl px-4 py-4 sm:px-6 md:px-24 md:py-16 lg:px-8 lg:py-20 container   ">
            <div className="row-gap-8 grid grid-cols-3 md:grid-cols-3 ">
              <div className="mb-12 text-center md:mb-0 md:border-r-2 ">
                <div className="font-heading text-[2.6rem] font-bold dark:text-main lg:text-5xl xl:text-8xl  ">
                  {counterone && (
                    <CountUp start={0} end={6} className="text-main" duration={2} delay={0} />
                  )}{" "}
                 <span className="text-main">+</span> 
                </div>
                <p className="text-sm font-medium uppercase tracking-widest    lg:text-base">
                  Years of Experience
                </p>
              </div>
              <div className="mb-12 text-center md:mb-0 md:border-r-2 ">
                <div className="font-heading text-[2.6rem] font-bold dark:text-main lg:text-5xl xl:text-8xl">
                  {counterone && (
                    <CountUp start={0} end={302} duration={2} className="text-main" delay={0} />
                  )}
                   <span className="text-main">+</span> 
                </div>
                <p className="text-sm font-medium uppercase tracking-widest   lg:text-base">
                  Satisfied Customers
                </p>
              </div>
              <div className="mb-12 text-center md:mb-0 md:border-r-2 ">
                <div className="font-heading text-[2.6rem] font-bold dark:text-main lg:text-5xl xl:text-8xl  ">
                  {counterone && (
                    <CountUp start={0}  className="text-main" end={6000} duration={2} delay={0} />
                  )}{" "}
                  <span className="responsive-desgin">+</span>
                </div>
                <p className="text-sm font-medium uppercase tracking-widest   lg:text-base">
                  Product Delivered
                </p>
              </div>
            </div>
          </div>
        </ScrollTrigger>
      </div>
      <About />
      <ProductHome />
      <Scrolll />
      <Customers />
      <Carouselproduct/>
    <Contact/>
    </>
  );
};

export default Home;
