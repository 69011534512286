import React from 'react'
import Licenseimg1 from '../assets/Certificate/C-1.jpg'
import Licenseimg2 from '../assets/Certificate/C-2.jpg'
import Licenseimg3 from '../assets/Certificate/C-3.jpg'
import Licenseimg4 from '../assets/Certificate/C-4.jpg'
const License = () => {
  return (
    <>
    <div className="bg-white h-max py-6 sm:py-8 mt-20 lg:py-12 mb-20   ">
  <div className="mx-auto max-w-screen-2xl px-4 md:px-8">
    <div className="mb-4 flex items-center justify-between gap-8 sm:mb-8 md:mb-12">
      <div className="flex items-center gap-12">
        <h2 className="text-2xl font-bold text-main lg:text-3xl ">
        License
        </h2>
        <p className="hidden max-w-screen-sm text-gray-500 md:block">
        FIRE SAFETY CERTIFICATE
        </p>
      </div>
      {/* <a
        href="#"
        className="inline-block rounded-lg border bg-white dark:bg-gray-700 dark:border-none px-4 py-2 text-center text-sm font-semibold text-gray-500 dark:text-gray-200 outline-none ring-indigo-300 transition duration-100 hover:bg-gray-100 focus-visible:ring active:bg-gray-200 md:px-8 md:py-3 md:text-base"
      >
        More
      </a> */}
    </div>
    <div className="grid grid-cols-2 gap-4 sm:grid-cols-3 md:gap-6 xl:gap-8">
      {/* image - start */}
      <a
        href="#"
        className="group relative flex h-48 items-end overflow-hidden rounded-lg bg-gray-100 shadow-lg md:h-80"
      >
        <img
          src={Licenseimg1}
          loading="lazy"
          alt="Photo by Minh Pham"
          className="absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110"
        />
        <div className="pointer-events-none absolute inset-0 bg-gradient-to-t from-gray-800 via-transparent to-transparent opacity-50"></div>
        <span className="relative ml-4 mb-3 inline-block text-sm text-white md:ml-5 md:text-lg">
        License-1
        </span>
      </a>
      {/* image - end */}
      {/* image - start */}
      <a
        href="#"
        className="group relative flex h-48 items-end overflow-hidden rounded-lg bg-gray-100 shadow-lg md:col-span-2 md:h-80"
      >
        <img
           src={Licenseimg2}
          loading="lazy"
          alt="Photo by Magicle"
          className="absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110"
        />
        <div className="pointer-events-none absolute inset-0 bg-gradient-to-t from-gray-800 via-transparent to-transparent opacity-50"></div>
        <span className="relative ml-4 mb-3 inline-block text-sm text-white md:ml-5 md:text-lg">
        License-2
        </span>
      </a>
      {/* image - end */}
      {/* image - start */}
      <a
        href="#"
        className="group relative flex h-48 items-end overflow-hidden rounded-lg bg-gray-100 shadow-lg md:col-span-2 md:h-80"
      >
        <img
           src={Licenseimg3}
          loading="lazy"
          alt="Photo by Martin Sanchez"
          className="absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110"
        />
        <div className="pointer-events-none absolute inset-0 bg-gradient-to-t from-gray-800 via-transparent to-transparent opacity-50"></div>
        <span className="relative ml-4 mb-3 inline-block text-sm text-white md:ml-5 md:text-lg">
        License-3
        </span>
      </a>
      {/* image - end */}
      {/* image - start */}
      <a
        href="#"
        className="group relative flex h-48 items-end overflow-hidden rounded-lg bg-gray-100 shadow-lg md:h-80"
      >
        <img
        src={Licenseimg4}
          loading="lazy"
          alt="Photo by Lorenzo Herrera"
          className="absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110"
        />
        <div className="pointer-events-none absolute inset-0 bg-gradient-to-t from-gray-800 via-transparent to-transparent opacity-50"></div>
        <span className="relative ml-4 mb-3 inline-block text-sm text-white md:ml-5 md:text-lg">
      License-4
        </span>
      </a>
      {/* image - end */}
    </div>
  </div>
</div>

    </>
  )
}

export default License
