import React, { useState } from "react";
import cogoToast from "cogo-toast";
import { FaArrowAltCircleRight } from "react-icons/fa";
import Home from "./Home";
import { Outlet } from "react-router-dom";
export const Contact = () => {
  const [result, setResult] = useState("");

  const onSubmit = async (event) => {
    event.preventDefault();
    setResult("Sending....");
    const formData = new FormData(event.target);

    formData.append("access_key", "f4cb950d-6c6f-4d69-9981-79a7fdae1af7");

    const response = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      body: formData,
    });

    const data = await response.json();

    if (data.success) {
      setResult("Form Submitted Successfully");
      event.target.reset();
    } else {
      console.log("Error", data);
      setResult(data.message);
    }
  };
  return (
    <form onSubmit={onSubmit}>
      <section className="text-gray-600 body-font relative ">
        <div className="container px-5 py-24 mx-auto flex sm:flex-nowrap flex-wrap ">
          <div className="lg:w-2/3 md:w-1/2 bg-gray-300 rounded-lg overflow-hidden sm:mr-10 p-10 flex items-end justify-start relative">
            <iframe
              width="100%"
              height="100%"
              className="absolute inset-0"
              frameBorder={0}
              title="map"
              marginHeight={0}
              marginWidth={0}
              scrolling="no"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3499.5057757752093!2d77.33769621214078!3d28.70442717552402!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cfbcc3843d4e1%3A0x1d5240f0428f5238!2sTech%20Safety!5e0!3m2!1sen!2sin!4v1713374608674!5m2!1sen!2sin"
            />
            <div className="bg-white relative flex flex-wrap py-6 rounded shadow-md">
              <div className="lg:w-1/2 px-6">
                <h2 className="title-font font-semibold text-gray-900 tracking-widest text-xs">
                  ADDRESS
                </h2>
                <p className="mt-1">
                  <a
                    target="_blank"
                    href="https://www.google.com/maps?ll=28.704427,77.340277&z=16&t=m&hl=en&gl=IN&mapclient=embed&cid=2112822575843201592"
                  >
                    <p>
                      Krishna Vihar, M-20, Kuti Rd, Bhopura, Ghaziabad, Uttar
                      Pradesh 201005,
                      <br />
                      INDIA
                    </p>
                  </a>
                </p>
              </div>
              <div className="lg:w-1/2 px-6 mt-4 lg:mt-0">
                <h2 className="title-font font-semibold text-gray-900 tracking-widest text-xs">
                  EMAIL
                </h2>
                <a  href=" mailto:info.techsafety2024@gmail.com" className="text-red-500 leading-relaxed"  >
                info.techsafety2024@gmail.com
                </a>
                <h2 className="title-font font-semibold text-gray-900 tracking-widest text-xs mt-4">
                  PHONE
                </h2>
                <p className="leading-relaxed">
                  {" "}
                  <a href="tel:+91 8826972993">+91 8826972993</a>
                </p>
              </div>
            </div>
          </div>
          <div className="lg:w-1/3 md:w-1/2 bg-white flex flex-col md:ml-auto w-full md:py-8 mt-8 md:mt-0">
            <h2 className="text-main text-3xl mb-1 font-medium title-font">
              Contact
            </h2>

            <div className="relative mb-4">
              <label htmlFor="name" className="leading-7 text-sm text-gray-600">
                Name
              </label>
              <input
                type="text"
                id="name"
                name="name"
                required
                className="w-full bg-white rounded border border-gray-300 focus:border-main focus:ring-2 focus:ring-red-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
              />
            </div>
            <div className="relative mb-4">
              <label
                htmlFor="email"
                className="leading-7 text-sm text-gray-600"
              >
                Email
              </label>
              <input
                type="email"
                id="email"
                required
                name="email"
                className="w-full bg-white rounded border border-gray-300 focus:border-main focus:ring-2 focus:ring-red-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
              />
            </div>
            <div className="relative mb-4">
              <label
                htmlFor="email"
                className="leading-7 text-sm text-gray-600"
              >
                phone no
              </label>
              <input
                type="number"
                required
                id="email"
                name="phone no"
                className="w-full bg-white rounded border border-gray-300 focus:border-main focus:ring-2 focus:ring-red-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
              />
            </div>
            <div className="relative mb-4">
              <label
                htmlFor="message"
                className="leading-7 text-sm text-gray-600"
              >
                Message
              </label>
              <textarea
                id="message"
                name="message"
                className="w-full bg-white rounded border border-gray-300 focus:border-main focus:ring-2 focus:ring-red-200 h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"
                defaultValue={""}
              />
            </div>
            <button className="text-white bg-main border-0 py-2 px-6 focus:outline-none hover:bg-yellow-300 rounded text-lg">
              Submit
            </button>
            <a
              href="https://veilmail.io/e/FkKh7o"
              className="mt-2 font-medium text-blue-600 hover:underline"
            >
              <span>{result}</span>
            </a>
          </div>
        </div>
      </section>
    </form>
  );
};
