import React from "react";
import { AiFillAccountBook } from "react-icons/ai";
const ProductCard = ({ item }) => {

  return (
    <>
      <div className="max-w-screen-xl mx-auto p-5 sm:p-10 mt-20 pt-12  md:p-16 ">
        <div className="border-b mb-5 flex justify-between text-sm">
          <div className="text-main flex items-center pb-2 pr-2 border-b-2 border-MainTwo uppercase">
            <AiFillAccountBook className="h-6 mr-3" />
            <a href="#" className="font-semibold inline-block">
              Our Product
            </a>
          </div>
          {/* <a href="#">Contact</a> */}
        </div>
        <div className="font-[sans-serif] p-4 mx-auto lg:max-w-5xl md:max-w-3xl max-w-lg">
          {/* <h2 className="text-4xl font-extrabold text-gray-800 mb-12">Laptops</h2> */}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8  ">
            {item.map((val) => (
              <div
                key={val.id}
                className="bg-white   overflow-hidden cursor-pointer shadow-lg transition-all relative"
              >
                <div className="w-full h-[250px] overflow-hidden mx-auto aspect-w-16 aspect-h-8 p-2">
                  <img
                    src={val.Imghero}
                    alt="laptop1"
                    className="h-full w-full object-contain"
                  />
                </div>
                <div className="p-6">
                  <hr className="border-2 mb-6" />
                  <div>
                    <h3 className="text-base text-gray-800">
                      {val.peoductAbout}
                    </h3>
                    <a
                      href="https://wa.me/+919315463262?text=Hii"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <h4 className="text-xl text-gray-800 font-bold mt-4">
                        <button
                          class="rounded-md border border-transparent py-2 px-4 text-center text-sm transition-all text-slate-600 bg-slate-100 focus:bg-slate-100 active:bg-slate-100 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                          type="button"
                        >
                          WhatsApp Us
                        </button>
                      </h4>
                    </a>
                  </div>
                  <div className="flex space-x-1.5 mt-4">
                    <a
                      href="tel:+91 8826972993"
                      class="relative inline-flex items-center justify-start px-6 py-3 overflow-hidden font-medium transition-all bg-main rounded-xl group"
                    >
                      <span className="absolute top-0 right-0 inline-block w-4 h-4 transition-all duration-500 ease-in-out bg-red-700 rounded group-hover:-mr-4 group-hover:-mt-4">
                        <span className="absolute top-0 right-0 w-5 h-5 rotate-45 translate-x-1/2 -translate-y-1/2 bg-white"></span>
                      </span>
                      <span className="absolute bottom-0 left-0 w-full h-full transition-all duration-500 ease-in-out delay-200 -translate-x-full translate-y-full bg-main rounded-2xl group-hover:mb-12 group-hover:translate-x-0"></span>
                      <span className="relative w-full text-left text-white transition-colors duration-200 ease-in-out group-hover:text-white">
                        Call us{" "}
                      </span>
                    </a>
                    <p className="text-base text-gray-800 "></p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductCard;
